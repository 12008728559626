import Api from './api.service';

export default {
  get: (params) => Api.get('patients', { params }),
  getId: (id) => Api.get(`patients/${id}`),
  save: (data) => Api.post(`patients/`, data),
  update: (id, data) => Api.put(`patients/${id}`, data),
  delete: (id) => Api.delete(`patients/${id}`),
  export: (term) => Api.post('patients/export', term),
  patch: (id, data) => Api.patch(`patients/${id}`, data),
};
