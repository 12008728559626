<template>
  <div class="appointment-list-view">
    <app-header icon="user-injured" title="Pacientes"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-content">
            <div class="row">
              <div class="col-4">
                <app-input
                  v-model="searchTerm"
                  label="Filtrar por Paciente"
                  placeholder="Nome, e-mail, CPF ou Telefone"
                  icon="search"
                >
                </app-input>
              </div>
              <div class="col-3">
                <b-field label="Ativo/Inativo">
                  <b-select
                    v-model="searchActive"
                    placeholder="Selecione..."
                    expanded
                  >
                    <option :value="-1">Todos</option>
                    <option :value="1">Ativo</option>
                    <option :value="0">Inativo</option>
                  </b-select>
                </b-field>
              </div>
              <div class="col-md-4">
                <b-field label="Selecionar período de cadastro">
                  <b-datepicker
                    v-model="dates"
                    :date-formatter="bDateRangeFormatter"
                    @input="onRangeChange($event)"
                    :years-range="[-100, 1]"
                    placeholder="Selecionar data..."
                    icon="calendar"
                    range
                  ></b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-2">
                <app-input
                  label="Resultados por página"
                  v-model="perPage"
                  type="number"
                  min="1"
                ></app-input>
              </div>
            </div>
          </div>
          <div class="card-header-buttons">
            <b-tooltip label="Novo Paciente">
              <router-link
                :to="{
                  name: 'patients.save',
                }"
              >
                <b-button size="is-small" type="is-success" icon-right="plus"
                  >Novo</b-button
                >
              </router-link>
            </b-tooltip>
            <b-tooltip label="Exportar XLS">
              <b-button
                class="ml-1"
                @click="exportPatients()"
                type="is-dark"
                size="is-small"
                icon-left="file-excel"
                :disabled="isExporting"
                :loading="isExporting"
              >
                Exportar
              </b-button>
            </b-tooltip>
          </div>
        </header>
        <div class="card-content">
          <hr class="separated-table" />
          <div class="row">
            <div class="col-4">
              <b-field label="Ordenar por" class="m-1">
                <b-select v-model="sorter" expanded>
                  <option value="asc,name">Nome</option>
                  <option value="asc,id">ID - menor pro maior</option>
                  <option value="desc,id">ID - maior pro menor</option>
                  <option value="desc,schedules_count">
                    Quantidade de consultas
                  </option>
                  <option value="desc,schedules_sum">
                    Valor total das consultas
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <b-table
            @page-change="onPageChange"
            :current-page.sync="page"
            :loading="isLoading"
            :per-page="perPage"
            :total="total"
            :data="data"
            paginated
            backend-pagination
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column v-slot="props" field="name" label="ID">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column v-slot="props" field="name" label="Nome">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="email" label="Email">
              {{ props.row.user.email || '-' }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="name"
              label="Qtd. Consultas"
            >
              {{ props.row.schedules_count }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="name"
              label="Valor R$"
            >
              R$
              {{ toMoney(props.row.schedules_sum) }}
            </b-table-column>

            <b-table-column v-slot="props" label="Ativo">
              <b-checkbox
                true-value="1"
                false-value="0"
                v-model="props.row.active"
                :patient_id="props.row.id"
                :value="props.row.active"
                class="mr-4"
                @input="
                  activePatient(props.row.active, props.row.id, props.row)
                "
              />
            </b-table-column>
            <b-table-column v-slot="props" label="Cadastro">
              {{ props.row.created_at | toDate }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Opções"
              cell-class="width-100"
            >
              <router-link
                :to="{
                  name: 'patients.save',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Editar" class="m-1">
                  <b-button
                    type="is-secondary"
                    size="is-small"
                    icon-right="edit"
                  />
                </b-tooltip>
              </router-link>
              <b-tooltip label="Deletar" class="m-1">
                <b-button
                  @click="onClickDelete(props.row.id)"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>
            </b-table-column>
            <template slot="footer">
              <div class="has-text-right">
                <div class=""><b>Total de pacientes:</b> {{ total }}<br /></div>
                <div>
                  <b>Valor total das consultas: R$ {{ total_amount }}</b>
                </div>
              </div>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PatientsService from '@/services/patients.service';
import debounce from 'lodash/debounce';
import * as moment from 'moment';

import BuefyMixin from '@/mixins/BuefyMixin';

export default {
  mixins: [BuefyMixin],
  data: () => ({
    isLoading: true,
    isExporting: false,
    data: [],
    total: 0,
    total_online: 0,
    total_patients: 0,
    total_amount: 0,
    page: 1,
    searchTerm: '',
    sorter: 'asc,name',
    onlines: '',
    perPage: 10,
    searchActive: -1,
    dates: [],
    datesFilter: [],
  }),
  filters: {
    toDate(value) {
      if (value) {
        return moment(new Date(value)).format('DD/MM/YYYY');
      }
    },
  },
  watch: {
    searchTerm: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),
    onlines() {
      this.page = 1;
      this.load();
    },
    sorter() {
      this.page = 1;
      this.load();
    },
    searchActive() {
      this.page = 1;
      this.load();
    },
    perPage() {
      if (this.perPage > this.data.length) this.load();
    },
  },
  methods: {
    activePatient(value, patient_id, row) {
      PatientsService.patch(patient_id, { active: value })
        .then(() => {
          this.$buefy.snackbar.open('Paciente atualizado com sucesso!');
        })
        .catch(({ response }) => {
          const { message, errors } = response;

          if (response.status === 422) {
            this.errors = errors;
            value == 1 ? (row.active = 0) : (row.active = 1);
          } else
            this.$buefy.snackbar.open(
              message || 'Erro ao tentar a atualizar o Paciente.',
              'error'
            );
          value == 1 ? (row.active = 0) : (row.active = 1);
        })
        .finally(() => (this.isSaving = false));
    },
    load() {
      this.isLoading = true;
      const params = {
        page: this.page,
        sort: this.sorter,
        perPage: this.perPage,
        'filter[name_email_document_or_phone]': this.searchTerm,
      };

      if (this.datesFilter.length == 2) {
        params[
          'filter[start_end_between]'
        ] = `${this.datesFilter[0]},${this.datesFilter[1]}`;
      }

      PatientsService.get(params)
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          this.total = total;
          this.page = current_page;
          this.data = Object.values(items);

          const values = this.data.map((x) => x.schedules_sum);
          this.total_amount = values.reduce((x, y) => (x += y));

          this.total_patients = data.total;
        })
        .finally(() => (this.isLoading = false));
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Paciente',
        message:
          'Tem certeza que deseja <b>excluir</b> este paciente? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Paciente',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      PatientsService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open('Paciente excluído com sucesso.');
          this.load();
        })
        .catch(({ response }) => {
          if (response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir o Paciente.',
              'error',
              4000
            );
          }
        });
    },
    exportPatients() {
      this.isExporting = true;
      PatientsService.export({ term: this.searchTerm })
        .then(({ data }) => {
          const link = document.createElement('a');
          link.target = '_blank';
          link.href = data;
          link.click();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || 'Erro ao realizar exportação.';
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isExporting = false));
    },
    onPageChange(page) {
      this.page = page;
      this.load();
    },
    onRangeChange(dates) {
      this.datesFilter = dates.map((date) => moment(date).format('YYYY-MM-DD'));

      this.load();
    },
    onDateRangeCleared() {
      this.dates = [];
      this.datesFilter = [];
      this.load();
    },
    toMoney(value) {
      return parseFloat(value).toFixed(2).replace(',', '.').replace('.', ',');
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style scoped>
.filters {
  align-items: center;
}
.separated-table {
  margin: 5px 0;
}
</style>
